.Navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    overflow: hidden;
    transition: .3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: black;
}

.Navbar-content {
    width: calc(100% - 50px);
    height: 100%;
    max-width: 1050px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Navbar-logo {
    margin: 31px auto auto 0;
    cursor: pointer;
    width: 166px;
}

.Navbar-logo img {
    width: 100%;
}


.Navbar-burguer {
    width: 40px;
    height: 40px;
    margin: 13px 0 auto 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: .3s ease-in-out;
}

@media(hover:hover) {
    .Navbar-burguer:hover {
        opacity: .7;
    }
}

.Navbar-burguer-topBun, .Navbar-burguer-burguer, .Navbar-burguer-bottomBun {
    background-color: #8FEC39;
    width: 100%;
    height: 4px;
    transition: .3s ease-in-out;
}

.Navbar-burguer-burguer {
    margin: 5px 0;
}

.Navbar-buttons {
    display: flex;
    align-items: center;
    justify-content: center ;
}

.Navbar-button {
    margin-left: 50px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    transition: .3s ease-in-out;
}

@media(hover:hover) {
    .Navbar-button:hover {
        opacity: .6;
    }
}

@media(max-width: 900px) {
    .Navbar-logo {
        width: 116px;
        margin: 21px auto auto 0;
    }

    .Navbar-buttons {
        width: 100%;
        padding: 10px 0;
        position: absolute;
        left: 0;
        top: 66px;
        flex-direction: column;
    }

    .Navbar-button {
        margin: 10px 0;
    }
}

@media(max-width: 300px) {
    .Navbar-content {
        width: calc(100% - 20px);
        padding: 0 10px;
    }
}