.QueHacemos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;
    width: calc(100% - 50px);
}

.QueHacemos h3 {
    font-size: 64px;
    font-weight: 800;
    font-style: italic;
    color: #8FEC39;
    margin-bottom: 25px;
    text-align: center;
}

.QueHacemos-tarjetas {
    margin: 25px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 800px;
}

.QueHacemos-tarjeta {
    margin: auto;
    background-color: transparent;
    border: none;
    padding: 50px;
    cursor: pointer;
    transition: .3s ease-in-out;
}

@media(hover:hover) {
    .QueHacemos-tarjeta:hover {
        opacity: .5;
    }
}

.QueHacemos-tarjeta img {
    height: 50px;
}

.QueHacemos-tarjeta h5 {
    font-weight: 700;
    color: #8FEC39;
    font-size: 21px;
    margin: 10px 0 0 0;
}

.QueHacemos-modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.QueHacemos-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.6));
    background: #000000;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 25px;
    width: calc(100% - 50px);
    max-width: 350px;
    position: relative;
}

.QueHacemos-modal-container img {
    height: 50px;
}

.QueHacemos-modal-container h5 {
    font-weight: 700;
    color: #8FEC39;
    font-size: 21px;
    margin: 10px 0 0 0;
    text-align: center;
}

.QueHacemos-modal-container p {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1px;
}

.QueHacemos-modal-container button {
    background-color: transparent;
    border: none;
    width: 15px;
    height: 15px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    transition: .3s ease-in-out;
}

@media(hover:hover) {
    .QueHacemos-modal-container button:hover {
        opacity: .6;
    }
}

@media(max-width: 900px) {
    .QueHacemos h3 {
        font-size: 56px;
    }

    .QueHacemos-tarjeta {
        padding: 32px;
    }
}

@media(max-width: 700px) {
    .QueHacemos-tarjeta {
        padding: 25px 0;
        width: 100%;
    }
}

@media(max-width: 500px) {
    .QueHacemos h3 {
        font-size: 42px;
        margin-bottom: 15px;
    }
}

@media(max-width: 400px) {
    .QueHacemos {
        padding: 0 25px;
    }

    .QueHacemos h3 {
        font-size: 33px;
        margin-bottom: 10px;
    }

    .QueHacemos-tarjeta img {
        height: 40px;
    }

    .QueHacemos-tarjeta h5 {
        font-size: 16px;
    }

    .QueHacemos-modal-container p {
        font-size: 14px;
        line-height: 20px;
        padding: 15px 20px;
    }
}

@media(max-width: 300px) {
    .QueHacemos h3 {
        font-size: 25px;
    }
}