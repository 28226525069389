.Portada {
    width: 100%;
    height: calc(100vw / 2.3 );
    margin: 0;
    margin-top: 96px;
    padding: 0;
    overflow: hidden;
    position: relative;
    transition: .3s ease-in-out;
}

.Portada-arrow {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: .3s ease-in-out;
    position: absolute;
    border-radius: 1000px;
    background-color: #000000;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(hover:hover) {
    .Portada-arrow:hover {
        opacity: .6;
    }
}

.Portada-arrow img {
    width: 100%;
}


.Portada-content {
    display: flex;
    height: 100%;
    transition: 1s ease-in-out;
}

.Portada-element {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
}

.Portada-element-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
}

.Portada-element-1 img {
    width: calc(100vw / 4.27);
    min-width: 200px;
}

.Portada-element-1 h2 {
    font-size: calc(100vw / 53.3);
    font-weight: 700;
    font-style: italic;
    text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
    letter-spacing: 0.05em;
}

.Portada-element-1-link {
    background-color: #8FEC39;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 20px;
    color: black;
    font-size: 18px;
    line-height: 18px;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease-in-out;
    margin-top: 50px;
}

@media(hover:hover) {
    .Portada-element-1-link:hover {
        opacity: .8;
    }
}

.Portada-element-2 {
    width: calc(100% - 100px);
    max-width: 750px;
    margin: auto;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    font-weight: 700;
    font-style: italic;
    font-size: 21px;
    color: #8FEC39;
    letter-spacing: 0.05em;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.6);
}

.Portada-element-2 b {
    color: white;
    font-family: 'Teko', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 36px;
}

.Portada-circles {
    position: absolute;
    bottom: 25px;
    left: calc(50% - 22px);
}

.Portada-circle, .Portada-circle-disabled {
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 1000px;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: 1s ease-in-out;
}

.Portada-circle {
    cursor: pointer;
    background-color: #8FEC39;
    transition: .3s ease-in-out;
}

@media(hover:hover) {
    .Portada-circle:hover {
        opacity: .7;
    }
}

.Portada-circle-disabled {
    background-color: #818181;
}

@media(max-width: 900px) {
    .Portada {
        height: calc(100vw / 1.5 );
        margin-top: 66px;
    }

    .Portada-element-1-link {
        padding: 10px 15px;
        font-size: 16px;
        line-height: 16px;
        margin-top: 25px;
    }

    .Portada-element-2 {
        height: calc(100% - 30px);
    }
}

@media(max-width: 860px) {
    .Portada-element-1 h2 {
        font-size: 17px;
    }
}

@media(max-width: 750px) {
    .Portada-arrow {
        width: 25px;
        height: 25px;
    }

    .Portada-circles {
        bottom: 10px;
    }

    .Portada-element-2 {
        width: calc(100% - 50px);
    }

    .Portada-element-2 {
        font-size: 18px;
    }
    
    .Portada-element-2 b {
        font-size: 30px;
        line-height: 30px;
    }
}

@media(max-width: 400px) {
    .Portada-arrow {
        display: none;
    }
    
    .Portada-element-1-link {
        margin-top: 10px;
    }

    .Portada-element-2 {
        font-size: 16px;
    }
    
    .Portada-element-2 b {
        font-size: 27px;
        line-height: 27px;
    }
}

@media(max-width: 320px) {
    .Portada-element-1-link {
        margin-bottom: 10px;
    }
}

@media(max-width: 300px) {
    .Portada-circles {
        display: none;
    }

    .Portada-element-1-link {
        margin-bottom: 0;
    }

    .Portada-element-2 {
        font-size: 14px;
    }
    
    .Portada-element-2 b {
        font-size: 24px;
        line-height: 24px;
    }
}