.Somos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;
    width: calc(100% - 50px);
}

.Somos h3 {
    font-size: 64px;
    font-weight: 800;
    font-style: italic;
    color: #8FEC39;
    margin-bottom: 25px;
    text-align: center;
}

.Somos p {
    background: #000000;
    border: 3px solid #8FEC39;
    box-sizing: border-box;
    border-radius: 39px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1px;
    font-weight: 400;
    text-align: center;
    padding: 25px 40px;
    max-width: 650px;
}

.Somos p b {
    font-weight: 600;
}

@media(max-width: 900px) {
    .Somos h3 {
        font-size: 56px;
    }
}

@media(max-width: 500px) {
    .Somos h3 {
        font-size: 42px;
        margin-bottom: 15px;
    }
}

@media(max-width: 400px) {
    .Somos {
        padding: 0 25px;
    }

    .Somos h3 {
        font-size: 33px;
        margin-bottom: 10px;
    }
    
    .Somos p {
        border-radius: 30px;
        font-size: 14px;
        line-height: 20px;
        padding: 15px 20px;
    }
}

@media(max-width: 300px) {
    .Somos h3 {
        font-size: 25px;
    }
}