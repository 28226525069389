.App {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.App-SomosHacemos {
    margin-top: 25px;
    width: 100%;
    min-height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.App-SomosHacemos-fadeTop, .App-SomosHacemos-fadeBottom {
    height: 25px;
    width: 100%;
    background: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0));
    position: absolute;
}

.App-SomosHacemos-fadeTop {
    top: 0;
    left: 0;
}

.App-SomosHacemos-fadeBottom {
    transform: rotate(180deg);
    bottom: 0;
    left: 0;
}

.App-YTVideo {
    width: calc(100% - 50px);
    height: calc((100vw - 50px) / 1.83 + 80px);
    max-width: 820px;
    max-height: 490px;
    margin: 100px 0;
}

@media(max-width: 600px) {
    .App-YTVideo {
        margin: 50px 0;
    }
}