.Formulario {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
}

.Formulario h3 {
    font-size: 56px;
    font-weight: 800;
    font-style: italic;
    color: #8FEC39;
    margin-bottom: 25px;
    text-align: center;
}

.Formulario-input {
    width: calc(100% - 20px);
    height: 18px;
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 6px;
    margin: 0 0 12px 0;
    color: black;
    font-weight: 500;
}

.Formulario-input::placeholder {
    color: #848383;
    font-weight: 400;
}

.Formulario-mensaje {
    width: 100%;
    height: 125px;
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 6px;
    resize: none;
    color: black;
    font-weight: 500;
}

.Formulario-submit {
    background-color: #8FEC39;
    border: none;
    border-radius: 0;
    cursor: pointer;
    padding: 10px 20px;
    color: black;
    font-size: 18px;
    line-height: 18px;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease-in-out;
    margin: 12px 0 0 auto;
}

@media(hover:hover) {
    .Formulario-submit:hover {
        opacity: .8;
    }
}

.Formulario-modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .8);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Formulario-modal img {
    width: calc(100% - 100px);
    max-width: 230px;
}

.Formulario-modal p {
    margin-top: -25px;
    text-align: center;
    width: calc(100% - 50px);
    max-width: 180px;
}

@media(max-width: 900px) {
    .Formulario-submit {
        width: 100%;
        border-radius: 5px;
    }
}

@media(max-width: 500px) {
    .Formulario h3 {
        font-size: 42px;
        margin-bottom: 15px;
    }
}

@media(max-width: 400px) {
    .Formulario h3 {
        font-size: 33px;
        margin-bottom: 10px;
    }

    .Formulario-modal p {
        margin-top: -6vw;
        font-size: 14px;
        max-width: 150px;
    }
}

@media(max-width: 300px) {
    .Formulario h3 {
        font-size: 25px;
    }
}