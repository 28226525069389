@import url('https://fonts.googleapis.com/css2?family=Georama:ital,wght@0,400;0,500;0,600;0,700;1,700;1,800&family=Rajdhani:wght@700&family=Teko:wght@700&display=swap');

body {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
  color: white;
}

* {
  font-family: 'Georama', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

*:focus, *:active {
  outline: none;
}