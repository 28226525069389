.Contacto {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 150px 0;
}

.Contacto-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
}

.Contacto-fadeTop, .Contacto-fadeBottom {
    height: 50px;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    position: absolute;
}

.Contacto-fadeTop {
    top: 0;
    left: 0;
}

.Contacto-fadeBottom {
    transform: rotate(180deg);
    bottom: 0;
    left: 0;
}

.Contacto-left {
    width: 100%;
    max-width: 359px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Contacto-logo {
    width: calc(100% - 50px);
    max-width: 359px;
}

.Contacto-social {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    width: calc(100% - 50px);
}

.Contacto-social-button {
    height: 30px;
    margin: 25px auto;
    transition: .3s ease-in-out;
}

@media(hover:hover) {
    .Contacto-social-button:hover {
        opacity: .5;
    }
}

.Contacto-social-button img {
    height: 100%;
}

.Contacto-right {
    width: 100%;
    max-width: 440px;
    margin-left: auto;
}

@media(max-width: 900px) {
    .Contacto {
        padding: 50px 0;
    }

    .Contacto-content {
        flex-direction: column-reverse;
    }

    .Contacto-left {
        width: 100%;
        max-width: 359px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 75px;
    }
    
    .Contacto-logo {
        width: calc(100% - 100px);
        max-width: 200px;
    }
    
    .Contacto-social {
        margin-top: 0px;
    }

    .Contacto-social-button {
        height: 25px;
        margin: 25px auto;
    }

    .Contacto-right {
        margin: auto;
    }
}

@media(max-width: 400px) {
    .Contacto-left {
        margin-top: 50px;
    }
}